import isAdminOwnerSuperAdmin from '../isAdminOwnerSuperAdmin';

export default function canManageNote(
  role = [],
  userId = '',
  authorId = ''
) {
  // User is editing their own notification
  if (userId === authorId) {
    return true;
  }

  if (isAdminOwnerSuperAdmin(role)) {
    // User is trying to edit a notification they didn't write
    // If they're role is owner+ allow them to edit/delete
    return true;
  }

  return false;
}

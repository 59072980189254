import React, { useEffect, useMemo, useState } from 'react';
import 'chart.js/auto';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isAdminOwnerSuperAdmin } from '@apprentage/utils';
import { groupByLocationAndDate } from '../../../../../services/apprenticeships';
import {
  canAccessAllLocations,
  canAccessApprenticeshipEmployers,
  canAccessApprenticeshipInstructionProviders,
  canAccessNoteApprenticeship
} from '../../../../../permissions/access';
import { getLogbookRecords, resetLogbookRecords } from '../../../../../actions/LogbookRecords';
import { daysAgo, mdDate } from '../../../../../utils/date';
import { getEmployers } from '../../../../../actions/Employers';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { getNoteMaterials, resetNoteApprenticeshipMaterials } from '../../../../../actions/Materials';
import DailyUsage from './DailyUsage';
import Vitals from './Vitals';
import OjtTracking from './OjtTracking';
import WageSchedules from './WageSchedules';
import NextActions from './NextActions';
import Notes from '../../../../Notes';
import InstructionProviders from './InstructionProviders';
import EmployerTable from './EmployerTable';
import TraineesTable from './TraineesTable';
// import UsageCard from './UsageCard';
// import { fetchWeeklyInsights } from '../../../../../services/weeklyInsights';

const Dashboard = () => {
  const dispatch = useDispatch();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const noteApprenticeshipMaterials = useSelector((state) => state.noteApprenticeshipMaterials);
  const logbookRecords = useSelector((state) => state.logbookRecords);
  const employers = useSelector((state) => state.employers);
  // Training Program
  const apprenticeshipId = apprenticeship?.id || '';
  // Organization
  const orgId = organization?.id || '';
  // Daily Usage
  const [numDaysAgo, setNumDaysAgo] = useState(90);
  const [numDaysAgoDate, setNumDaysAgoDate] = useState(
    daysAgo(numDaysAgo).toISOString()
  );
  // Materials
  const noteType = 'apprenticeship';
  const noteTypeId = apprenticeship?.id || null;

  // High Usage Low Usage
  // const [isLoading, setIsLoading] = useState(false);
  // const [listUsage, setListUsage] = useState(null);

  // Current User
  const locationId = currentUser?.locationId || '';
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Employers
  const employersHash = useMemo(() => {
    return employers.hash || {};
  }, [employers.hash]);

  const showEmployersInstructionProviders = useMemo(() => {
    return canAccessApprenticeshipEmployers(role) && canAccessApprenticeshipInstructionProviders(role);
  }, [role]);

  // const fetchList = async (employerId) => {
  //   setIsLoading(true);
  //   const res = await fetchWeeklyInsights({
  //     employerId,
  //     apprenticeshipId,
  //     orgId
  //   });
  //   if (res) {
  //     setListUsage(res);
  //   } else {
  //     setListUsage(null);
  //   }
  //   setIsLoading(false);
  // };

  useEffect(() => {
    if (apprenticeship?.id) {
      dispatch(getLogbookRecords({
        limit: 1000,
        orgId,
        apprenticeshipId: apprenticeship?.id,
        gteDate: numDaysAgoDate,
        // Program Manager or Trainer
        ...(canAccessAllLocations(role) ? {} : { locationId })
      }));
    }
  }, [apprenticeship?.id, apprenticeshipId, dispatch, locationId, numDaysAgoDate, orgId, role]);

  useEffect(() => {
    if (apprenticeship?.id && canAccessNoteApprenticeship(role)) {
      dispatch(getNoteMaterials({
        noteType,
        noteTypeId,
        orgId
      }));
    }
  }, [apprenticeship?.id, dispatch, noteTypeId, orgId, role]);

  useEffect(() => {
    dispatch(
      getEmployers({
        orgId,
        ...(apprenticeship?.employerIds
          ? { locationIds: apprenticeship?.employerIds }
          : {})
      })
    );
  }, [apprenticeship?.employerIds, dispatch, orgId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetLogbookRecords());
      dispatch(resetNoteApprenticeshipMaterials());
    };
  }, [dispatch]);

  const onChangeUsageDate = (e) => {
    const { value } = e.currentTarget;

    const num = parseInt(value, 10);
    setNumDaysAgo(num);
    setNumDaysAgoDate(daysAgo(num).toISOString());
  };

  const callbackPrimaryAction = () => {
    const config = {
      orgId,
      apprenticeshipId: apprenticeship?.id,
      gteDate: numDaysAgoDate,
      // Program Manager or Trainer
      ...(canAccessAllLocations(role) ? {} : { locationId })
    };

    dispatch(getLogbookRecords(config));
  };

  const records = useMemo(() => {
    const allRecords = logbookRecords?.list || [];
    const filteredRecordsWithLocation = groupByLocationAndDate(allRecords);

    return filteredRecordsWithLocation.map((employerRecords) => {
      const employerName = employersHash[employerRecords?.locationId]
        && employersHash[employerRecords?.locationId]?.name;
      return {
        ...employerRecords,
        name: employerName || 'Employer'
      };
    });
  }, [logbookRecords?.list, employersHash]);

  const labels = useMemo(() => {
    const allRecords = logbookRecords?.list || [];
    return allRecords.reduce((acc, curr) => {
      const dateArr = curr.date.split('T');
      const [dateKey] = dateArr;

      if (acc[dateKey] === undefined) {
        return {
          ...acc,
          [dateKey]: mdDate(curr.date)
        };
      }
      return acc;
    }, {});
  }, [logbookRecords?.list]);

  const showNotes = useMemo(() => {
    if (canAccessNoteApprenticeship(role)) {
      return Array.isArray(noteApprenticeshipMaterials?.list) && noteApprenticeshipMaterials?.list.length > 0;
    }

    return false;
  }, [noteApprenticeshipMaterials?.list, role]);

  if (
    apprenticeship?.id
    && Array.isArray(currentUser?.apprenticeshipIds)
    && !currentUser?.apprenticeshipIds.includes(apprenticeship?.id)
  ) {
    if (!isAdminOwnerSuperAdmin(role)) {
      return (
        <Redirect to={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS} />
      );
    }
  }

  if (!showNotes) {
    return (
      <div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-8">
            <div className="row">
              <div className="col-12 my-2 my-lg-3">
                <Vitals />
              </div>
              <div className="col-12 col-lg-6 mb-4">
                <OjtTracking />
              </div>
              <div className="col-12 col-lg-6 mb-4">
                <WageSchedules />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-4">
            <NextActions />
          </div>
        </div>

        {/* <div className="col-12 col-md-6 mb-4">
            <div className="row h-100">
              <div className="col-6">
              <UsageCard
              title="High Usage"
              isLoading={isLoading}
              listUsage={listUsage?.highUsage ?? []}
              />
              </div>
              <div className="col-6">
              <UsageCard
              title="Low Usage"
              isLoading={isLoading}
              listUsage={listUsage?.lowUsage ?? []}
              />
              </div>
              </div>
              </div>
            <div className="col-12 col-md-6 mb-4" /> */}

        {!showEmployersInstructionProviders && (
          <div className='row'>
            <div className="col-12 mb-4">
              <TraineesTable />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12 mb-4">
            <DailyUsage
              rawData={records}
              rawLabels={labels}
              numDaysAgo={numDaysAgo}
              onChangeUsageDate={onChangeUsageDate}
              callbackPrimaryAction={callbackPrimaryAction}
            />
          </div>
        </div>

        {showEmployersInstructionProviders && (
          <div className='row'>
            <div className="col-12 col-lg-6 mb-4">
              <EmployerTable
                orgId={orgId}
                className='overflow-hidden'
                apprenticeshipId={apprenticeshipId}
                employerList={
                  apprenticeship?.employerIds?.length ? employers?.list : []
                }
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <InstructionProviders />
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-8">
          <div className="row">
            <div
              className="col-12 col-lg-6 my-2 my-lg-3" // col-12
            >
              <Vitals />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <NextActions />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <OjtTracking />
            </div>
            <div className="col-12 col-lg-6 mb-4 d-block d-md-none d-lg-block">
              <WageSchedules className='h-100' />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mb-4">
          <Notes
            noteType={noteType}
            noteTypeId={noteTypeId}
            list={noteApprenticeshipMaterials?.list}
            pagination={noteApprenticeshipMaterials?.pagination}
          />
          <WageSchedules className='mt-4 d-none d-md-block d-lg-none' />
        </div>
      </div>

      {!showEmployersInstructionProviders && (
        <div className='row'>
          <div className="col-12 mb-4">
            <TraineesTable />
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12 mb-4">
          <DailyUsage
            rawData={records}
            rawLabels={labels}
            numDaysAgo={numDaysAgo}
            onChangeUsageDate={onChangeUsageDate}
            callbackPrimaryAction={callbackPrimaryAction}
          />
        </div>
      </div>

      {showEmployersInstructionProviders && (
        <div className='row'>
          <div className="col-12 col-lg-6 mb-4">
            <EmployerTable
              orgId={orgId}
              className='overflow-hidden'
              apprenticeshipId={apprenticeshipId}
              employerList={
                apprenticeship?.employerIds?.length ? employers?.list : []
              }
            />
          </div>
          <div className="col-12 col-lg-6 mb-4">
            <InstructionProviders />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaCalendarAlt } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import { fetchLogbookRecords } from '../../../../../../services/logbookRecords';
import NumberCard from '../../../../../NumberCard';
import { mdyDate } from '../../../../../../utils/date';

const LastActivity = ({
  className = '',
  text = 'Last Activity',
  slim
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const logbook = useSelector((state) => state.logbook);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Organization
  const orgId = organization?.id || '';
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;
  const apprenticeshipUserId = apprenticeship?.user?.id || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const [mostRecentRecordDate, setMostRecentRecordDate] = useState('');

  const retrieveData = useCallback(() => {
    setLoading(true);
    if (logbook.fetched && logbook?.id) {
      fetchLogbookRecords({
        orgId,
        logbookId: logbook?.id,
        select: ['createdAt', 'date', 'id'],
        limit: 1
      }).then((response) => {
        if (Array.isArray(response.items) && response.items.length === 1) {
          setMostRecentRecordDate(response.items[0]?.date);
        }

        setLoading(false);
      });
    } else {
      fetchLogbookRecords({
        apprenticeshipId,
        approvedByUserId: apprenticeshipUserId,
        select: ['approvedAt', 'date', 'id'],
        isApproved: true,
        orgId,
        order: '-approvedAt',
        limit: 1
      }).then((response) => {
        if (Array.isArray(response.items) && response.items.length === 1) {
          setMostRecentRecordDate(response.items[0]?.date);
        }

        setLoading(false);
      });
    }
  }, [apprenticeshipId, apprenticeshipUserId, logbook.fetched, logbook?.id, orgId]);

  useEffect(() => {
    retrieveData();
  }, [retrieveData]);

  if (slim) {
    return (
      <div className={`border rounded mt-3 ${className}`}>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center p-3 bg-light border-right'>
            <FaCalendarAlt className='mr-1' />
            <div>
              Last Activity
            </div>
          </div>

          {loading ? (
            <div className='w-50 pr-3'>
              <Skeleton
                count={1}
                style={{ height: '37.5px' }}
              />
            </div>
          ) : (
            <div
              className='h5 m-0 p-3'
            >
              {mostRecentRecordDate ? mdyDate(mostRecentRecordDate) : '--'}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={`row ${className}`}>
      <div className="col">
        <NumberCard
          loading={loading}
          size='sm'
          className='mb-3'
          num={mostRecentRecordDate ? mdyDate(mostRecentRecordDate) : '--'}
          text={text}
          icon={() => <FaCalendarAlt className='mr-1' />}
        />
      </div>
    </div>
  );
};

export default LastActivity;

import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import { TURBINE_PAGE } from '../constants/urls';
import { mdySimple } from '../utils/date';
import {
  createFirebaseEntry, deleteFirebaseEntry, getFirebaseEntriesNew, updateFirebaseEntry
} from './firebaseProxy';

const collection = 'invites';

/**
 * @param {Array} ids
 */
export const fetchInvites = ({
  ids = [],
  locationId,
  employerProfileId,
  apprenticeshipId,
  inviteType
}) => {
  return new Promise((resolve, reject) => {
    const config = {};

    // Training Program: Workforce
    if (locationId) {
      config['f.locationId[eq]'] = locationId;
    }

    if (employerProfileId) {
      config['f.employerProfileId[eq]'] = employerProfileId;
    }

    if (apprenticeshipId) {
      config['f.apprenticeshipId[eq]'] = apprenticeshipId;
    }

    if (inviteType) {
      config['f.inviteType[eq]'] = inviteType;
    }

    if (ids.length) {
      config.ids = ids.join(',');
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    getFirebaseEntriesNew({
      collection,
      ...config
    }).then(({ items }) => {
      const list = items.length ? items : null;

      resolve(list);
    }).catch((error) => {
      console.error(`fetch ${collection}`, error);
      reject(error);
    });
  });
};

export const createInvite = (data) => {
  return new Promise((resolve) => {
    createFirebaseEntry({ data, collection }).then((response) => {
      const docId = pathOr(null, ['docId'], response);

      resolve(docId);
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error(`create ${collection}`, error);
    });
  });
};

/**
 *
 * @param {string} id // "docId"
 * @param {object} data
 */
export const updateInvite = (data, id) => {
  return new Promise((resolve) => {
    updateFirebaseEntry({
      collection,
      data,
      id
    }).then(() => {
      resolve();
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error(`update ${collection}`, error);
    });
  });
};

/**
 *
 * @param {string} id // "docId"
 * @param {object} data
 */
export const deleteInvite = (id) => {
  return new Promise((resolve) => {
    deleteFirebaseEntry({
      collection: 'invites',
      id
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error(`delete ${collection}`, error);
    });
  });
};

export const inviteStatus = ({
  sentAt = '',
  viewedAt = ''
}) => {
  let text = 'sent';
  let className = ' border bg-light';
  let dataTip = `Sent: ${mdySimple(sentAt)}`;

  if (viewedAt) {
    text = 'viewed';
    className = 'info';
    dataTip = `Viewed: ${mdySimple(viewedAt)}`;
  }

  return {
    text,
    className,
    dataTip
  };
};

export const generateInviteLink = (id) => {
  return `${TURBINE_PAGE}/external/invite/id/${id}`;
};

import pluralize from 'pluralize';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ListCard from '@apprentage/components/dist/components/ListCard';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../constants/routes';

const CardsDisplay = ({
  data,
  onClickRow
}) => {
  const history = useHistory();

  const getDifferenceInDays = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const diffInMs = Math.abs(endDate - startDate);
    const diffInDays = Math.ceil(diffInMs / oneDay);
    return diffInDays;
  };

  const generateTagsList = useCallback((program) => {
    // Determine the most used classifications in the Training Program

    // Instantiate Map to determine how many times each classification is
    // attached to a competency
    const classificationHash = new Map();

    // Iterate through Training Program keys
    for (const programKey in program?.competencies) {
      // Iterate through Training Program Competencies
      for (const compKey in program?.competencies[programKey]) {
        if (compKey === 'classificationIds') {
          // Iterate through Training Program Competency's Classifications
          for (const classificationIds in program?.competencies[programKey][compKey]) {
            // Create map entry if none exists
            if (!classificationHash.get(classificationIds)) {
              classificationHash.set(classificationIds, 0);
            }
            // Increment existing map entry by 1
            classificationHash.set(classificationIds, classificationHash.get(classificationIds) + 1);
          }
        }
      }
    }
    // Sort Map entries
    const sortedClassifications = new Map([...classificationHash.entries()].sort((a, b) => b[1] - a[1]));
    // console.log(sortedClassifications);

    // Convert classificationIds to classification titles
    return [...sortedClassifications.keys()].map((id) => program?.classifications[id]);
  }, []);

  if (!data || (data && data.length === 0)) {
    return (
      <div className='bg-white shadow rounded'>
        <div className="py-5 d-flex justify-content-center flex-column">
          <div className="mt-3 w-100 text-center">No Training Programs</div>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      {Array.isArray(data) && data.map((item) => {
        const tagsList = generateTagsList(item);
        const durationCurrent = getDifferenceInDays(
          new Date(item.startDate),
          new Date()
        );
        const durationTotal = getDifferenceInDays(
          new Date(item.startDate),
          new Date(item.expectedEndDate)
        );
        const employerIds = item?.employerIds || [];
        const rightCornerText = (!employerIds.length) ? '' : pluralize('Employer', employerIds.length, true);

        return (
          <div
            key={item.id}
            className="col-12 col-md-6 col-xl-4 mb-4 d-flex"
          >
            <ListCard
              title={item.title}
              className="w-100 bg-white"
              durationText={
                durationTotal
                  ? `${durationTotal - durationCurrent} days remaining`
                  : ''
              }
              durationCurrent={durationCurrent}
              durationTotal={durationTotal}
              tagsList={tagsList}
              rightCornerText={rightCornerText}
              primaryOnClick={() => {
                const apprenticeshipId = item?.id || '';

                if (apprenticeshipId) {
                  if (onClickRow) {
                    onClickRow(apprenticeshipId);
                    return;
                  }

                  history.push(`${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`);
                }
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CardsDisplay;

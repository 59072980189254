import React, {
  Fragment, useCallback, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHEET_KEY_MATERIAL } from '../../constants/sideSheets';
import { getMaterial, getNoteMaterials } from '../../actions/Materials';
import { handleOpenUserSideSheet, setSideSheet } from '../../actions/SideSheets';
import Note from './Note';
import PaginationSupabase from '../ManageContent/PaginationSupabase';
import {
  canAccessNoteApprenticeship, canAccessNoteLocation, canAccessNoteUser
} from '../../permissions/access';

const Notes = ({
  noteType,
  noteTypeId,
  list = null,
  pagination = null
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Organization
  const orgId = organization?.id || null;
  // Materials
  const hasMaterials = useMemo(() => {
    return Array.isArray(list) && list.length > 0;
  }, [list]);

  const [listConfig, setListConfig] = useState({
    noteType,
    noteTypeId,
    orgId
  });

  const retrieveNoteMaterials = useCallback(() => {
    if (noteType && noteTypeId) {
      if (
        (noteType === 'user' && canAccessNoteUser(role))
        || (noteType === 'location' && canAccessNoteLocation(role))
        || (noteType === 'apprenticeship' && canAccessNoteApprenticeship(role))
      ) {
        dispatch(getNoteMaterials(listConfig));
      }
    }
  }, [noteTypeId, noteType, role, dispatch, listConfig]);

  const onClickMaterial = (id, editMode = false) => {
    dispatch(getMaterial(id)).then(() => {
      dispatch(setSideSheet({
        key: SHEET_KEY_MATERIAL,
        data: {
          editMode,
          tinyMceEditor: {
            uploadPdfButton: false,
            embedMediaButton: false,
            uploadImgButton: false
          },
          noteType,
          noteTypeId
        },
        className: 'MaterialSideSheet',
        callbackPrimaryAction: () => {
          retrieveNoteMaterials();
        }
      }));
    });
  };

  const onClickTeamMember = (userId) => {
    dispatch(handleOpenUserSideSheet({
      userId,
      orgId,
      currentUserOrgId: currentUser?.orgId
      // route,
      // history
    }));
  };

  if (!hasMaterials) {
    return null;
  }

  return (
    <div
      className="Apprenticeship-User-Notes h-lg-100"
    >
      <div className='h-lg-100'>
        <div className='shadow rounded bg-white border h-100'>
          {list.map((material) => (
            <Fragment key={material?.id}>
              <Note
                className={pagination?.total > 1 ? 'noteMaterials-pagination' : ''}
                materialId={material?.id}
                authorId={material?.userId}
                createdAt={material?.createdAt}
                body={material?.body}
                onClickTeamMember={onClickTeamMember}
                onClickEdit={(id) => {
                  onClickMaterial(id, true);
                }}
                onClickView={(id) => {
                  onClickMaterial(id);
                }}
              />
            </Fragment>
          ))}

          {hasMaterials && (
            <div className='card-footer py-0 px-2'>
              <PaginationSupabase
                items={list}
                pagination={pagination}
                page={pagination?.page}
                className=''
                style={{}}
                onClickNext={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: pagination?.page + 1
                  }));
                  // goToElem('root');
                }}
                onClickPrev={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: pagination?.page - 1
                  }));
                  // goToElem('root');
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notes;

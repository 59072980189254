import { client, flattenItem } from '../services/contentful';
import {
  RESET_MATERIAL,
  RESET_MATERIALS,
  RESET_NOTE_APPRENTICESHIP_MATERIALS,
  SET_ENTRY_ORG_ID,
  SET_MATERIAL,
  SET_MATERIALS,
  SET_NOTE_APPRENTICESHIP_MATERIALS,
  SET_NOTE_LOCATION_MATERIALS,
  SET_NOTE_USER_MATERIALS
} from './types';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { fetchMaterial, fetchMaterialByResourceId, fetchMaterials } from '../services/materials';
import { getResourcesByParentId } from './Resources';

const table = 'materials';

export const getMaterials = ({
  orgId,
  userId = null,
  isDraft = null,
  workflowType = undefined,
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1,
  order = 'title',
  title = ''
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchMaterials({
        orgId,
        userId,
        isDraft,
        workflowType,
        page,
        limit,
        order,
        title
      })
        .then((response) => {
          const list = response?.items || [];
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_MATERIALS,
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });

          resolve(response?.items);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getMaterial = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchMaterial(id)
        .then((material) => {
          if (!material?.id) {
            reject(new Error('No material found (id)'));
            return;
          }

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: material?.orgId || null
          });

          dispatch({
            type: SET_MATERIAL,
            material: material?.id ? { ...material, cdnFiles: null } : null
          });
          dispatch(getResourcesByParentId(material?.id, 'materials'));
          resolve(material);
        })
        .catch((error) => {
          console.error(`get ${table}`, error);
          reject(error);
        });
    });
  };
};

// TODO refactor to not use this Function
// Material can be fetched with fetchMaterial with resource?.contentId
export const getResourceMaterial = ({ resourceId, orgId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchMaterialByResourceId({ resourceId, orgId })
        .then((material) => {
          if (!material?.id) {
            reject(new Error('No material found (id)'));
            return;
          }

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: material?.orgId || null
          });

          dispatch({
            type: SET_MATERIAL,
            material: material?.id ? { ...material, cdnFiles: null } : null
          });
          dispatch(getResourcesByParentId(material?.id, 'materials'));
          resolve(material);
        })
        .catch((error) => {
          console.error(`get ${table}`, error);
          reject(error);
        });
    });
  };
};

export const setMaterial = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      client.getEntry(id).then((response) => {
        const material = response ? flattenItem(response) : null;

        dispatch({
          type: SET_MATERIAL,
          material,
          cdnFiles: material?.integration?.cdnFiles || null
        });
        resolve(material);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

const actionsFromType = (noteType) => {
  switch (noteType) {
    case 'user':
      return SET_NOTE_USER_MATERIALS;
    case 'location':
      return SET_NOTE_LOCATION_MATERIALS;
    case 'apprenticeship':
      return SET_NOTE_APPRENTICESHIP_MATERIALS;
    default:
      return undefined;
  }
};

export const getNoteMaterials = ({
  noteType,
  noteTypeId,
  orgId,
  limit = 1,
  page = 1,
  order = '-createdAt'
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!noteType || !noteTypeId) {
        reject(new Error('Missing required argument, noteType or noteTypeId'));
        return;
      }

      fetchMaterials({
        orgId,
        noteType,
        noteTypeId,
        page,
        limit,
        order
      }).then((response) => {
        const list = response?.items || [];
        const type = actionsFromType(noteType);

        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: list && list[0] ? list[0]?.orgId : null
        });

        if (type) {
          dispatch({
            type,
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });
          resolve(response);
        }
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetNoteApprenticeshipMaterials = () => {
  return (dispatch) => {
    dispatch({ type: RESET_NOTE_APPRENTICESHIP_MATERIALS });
  };
};

export const resetMaterial = () => {
  return (dispatch) => {
    dispatch({ type: RESET_MATERIAL });
  };
};

export const resetMaterials = () => {
  return (dispatch) => {
    dispatch({ type: RESET_MATERIALS });
  };
};

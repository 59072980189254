import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

const NumberCard = ({
  num,
  text,
  className = '',
  size = 'lg',
  icon,
  loading
}) => {
  const style = useMemo(() => {
    switch (size) {
      case 'sm':
        return {
          fontSize: '25px',
          height: '37.5px'
        };
      case 'md':
        return {
          fontSize: '35px',
          height: '47.5px'
        };
      case 'lg':
      default:
        return {
          fontSize: '45px',
          height: '57.5px'
        };
    }
  }, [size]);

  return (
    <div className={`card ${className}`}>
      <div className="card-body h-100">
        <div
          className='d-flex align-items-center justify-content-center'
          style={{
            minHeight: '100%',
            fontSize: style.fontSize
          }}
        >
          {loading ? (
            <div className='w-50'>
              <Skeleton
                count={1}
                style={{ height: style.height }}
              />
            </div>
          ) : num}
        </div>
      </div>
      <div className="card-footer d-flex align-items-center justify-content-center">
        <span className='d-flex align-items-center'>{icon ? icon() : ''}{text}</span>
      </div>
    </div>
  );
};

export default NumberCard;

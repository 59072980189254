import React from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { GiTeacher } from 'react-icons/gi';
import { FaPlus } from 'react-icons/fa';
import { withAuthorization } from '../../Session';
import { ORG_CONSOLE_WORKFORCE } from '../../../constants/routes';
import withOrgConsole from '../../App/withOrgConsole';
import OrgConsoleHeader from '../OrgConsoleHeader';
import InstructionProvidersList from '../../lists/InstructionProvidersList';
import { setCurrentModal } from '../../../actions/Modals';

const InstructionProviders = () => {
  const dispatch = useDispatch();

  const handleShowChangePin = () => {
    dispatch(
      setCurrentModal({
        key: 'changePinInstructionProvider',
        data: {}
      })
    );
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Instruction Providers"
            iconComponent={() => (
              <GiTeacher
                size={25}
                className="mr-2"
              />
            )}
            route={ORG_CONSOLE_WORKFORCE}
          >
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={handleShowChangePin}
            >
              <span className='d-flex align-items-center'>
                <FaPlus />
                <span className='ml-1'>
                  New
                </span>
              </span>
            </button>
          </OrgConsoleHeader>

          <div className="bg-white border shadow rounded mt-3 p-3 overflow-content">
            <InstructionProvidersList />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(InstructionProviders);

import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT,
  INVITE_TYPE_EMPLOYER_PROFILE_UPDATE
} from '@apprentage/constants';
import ReactTooltip from 'react-tooltip';
import Collapse from '@apprentage/components/dist/components/Collapse';
import {
  FaCheckCircle, FaChevronRight, FaExclamationTriangle, FaInfoCircle
} from 'react-icons/fa';
import {
  employerInviteStatuses,
  hasRequiredWorkforceInformation
} from '../../../../../services/employers';
import { setCurrentModal } from '../../../../../actions/Modals';
import { routeWithOrgId } from '../../../../../services/organizations';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { MODAL_KEY_USER_INVITATION } from '../../../../../constants/modals';

const ChecklistRow = styled.div`
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  div {
    align-items: center;
    display: flex;

    i {
      font-size: 1.5rem;
    }
  }

  div > span {
    font-size: 0.9rem;
    margin-left: 0.5rem;
  }
`;

const WorkforceInformationChecklist = ({
  title = 'Employer Checklist',
  className = 'card mt-3',
  data // employer || orgLocation
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const employerProfile = useSelector((state) => state.employerProfile);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const invites = useSelector((state) => state.invites);
  const wageSchedules = useSelector((state) => state.wageSchedules);
  const employerAgreement = useSelector((state) => state.employerAgreement);
  // Employer
  const locationId = data?.id || null;
  const addressState = data?.addressState || null;
  // Employer Agreement
  const employerAgreementId = employerAgreement?.id || '';
  // Training Program
  const apprenticeshipId = apprenticeship?.id || '';
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
  // Employer Profile
  const employerProfileId = employerProfile?.id || null;
  // Invites
  const inviteHistory = employerProfile?.inviteHistory || [];
  const invitesList = invites?.list || [];
  // Organization
  const orgId = organization?.id || '';

  const requiredWorkforceInformation = useMemo(() => {
    if (employerProfile?.id) {
      return hasRequiredWorkforceInformation(employerProfile);
    }

    return false;
  }, [employerProfile?.id]);

  const hasPointOfContact = employerProfile?.contactEmail;
  const inviteStatuses = employerInviteStatuses({
    invitesList,
    inviteHistory
  });

  const inviteCtaSignAgreementMgmt = 'Request Employer Sign Agreement';

  const checklistComplete = useMemo(() => {
    if (!requiredWorkforceInformation) {
      return false;
    }

    if (!hasPointOfContact) {
      return false;
    }

    if (employerAgreementId && employerAgreement?.signerSignature) {
      return true;
    }

    const inviteStatusesTrue = Object.values(inviteStatuses).filter(
      (status) => status === true
    );

    if (Object.keys(inviteStatuses).length !== inviteStatusesTrue.length) {
      return false;
    }

    return true;
  }, [requiredWorkforceInformation, hasPointOfContact, employerAgreementId, employerAgreement?.signerSignature, inviteStatuses]);

  const manageEmployerProfile = () => {
    dispatch(
      setCurrentModal({
        key: 'manageEmployerProfile'
      })
    );
  };

  const manageEmployerPointOfContact = () => {
    dispatch(
      setCurrentModal({
        key: 'manageEmployerPointOfContact',
        data: {
          locationId
        }
      })
    );
  };

  const createWageSchedule = () => {
    dispatch(
      setCurrentModal({
        key: 'manageWageSchedule',
        data: {
          addressState
        }
      })
    );
  };

  // const requestEmployerCreateWageSchedule = () => {
  //   dispatch(
  //     setCurrentModal({
  //       key: MODAL_KEY_USER_INVITATION,
  //       data: {
  //         locationId,
  //         employerProfileId,
  //         apprenticeshipId,
  //         singleUserInvite: true,
  //         includeName: true,
  //         ...(employerProfile?.contactEmail ? { email: employerProfile?.contactEmail } : {}),
  //         ...(employerProfile?.contactFirstName ? { firstName: employerProfile?.contactFirstName } : {}),
  //         ...(employerProfile?.contactLastName ? { lastName: employerProfile?.contactLastName } : {}),
  //         inviteType: INVITE_TYPE_EMPLOYER_PROFILE_UPDATE,
  //         inviteTypeTitle: 'Request Employer Create Wage Schedule'
  //       }
  //     })
  //   );
  // };

  const inviteToUpdateEmployerProfile = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_USER_INVITATION,
        data: {
          modalTitle: 'New Request',
          locationId,
          employerProfileId,
          apprenticeshipId,
          singleUserInvite: true,
          includeName: true,
          inviteType: INVITE_TYPE_EMPLOYER_PROFILE_UPDATE,
          inviteTypeTitle: 'Request Employer Update Workforce Data'
        }
      })
    );
  };

  const inviteToSignEmployerAgreement = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_USER_INVITATION,
        data: {
          modalTitle: 'New Request',
          locationId,
          employerProfileId,
          apprenticeshipId,
          singleUserInvite: true,
          includeName: true,
          includeTitle: true,
          inviteType: INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT,
          inviteTypeTitle: inviteCtaSignAgreementMgmt
        }
      })
    );
  };

  return (
    <>
      <Collapse
        title={title}
        id="courseLongDescription"
        className={className}
        p={0}
        badge={() => {
          return (
            <span className='h6 mb-0 ml-2 py-2'>
              {checklistComplete ? (
                <span className='badge bg-keppel text-white'>
                  <span className='d-flex align-items-center'>
                    <FaCheckCircle className='mr-1' />
                    <span>
                      Complete
                    </span>
                  </span>
                </span>
              ) : (
                <span className='badge bg-warning'>
                  <span className='d-flex align-items-center'>
                    <FaExclamationTriangle className='mr-1' />
                    <span>
                      Incomplete
                    </span>
                  </span>
                </span>
              )}
            </span>
          );
        }}
        ariaExpanded={!checklistComplete}
      >
        <ChecklistRow>
          <div>
            <FaCheckCircle
              size={24}
              className={hasPointOfContact ? 'text-keppel' : 'text-muted opacity-25'}
            />
            <span>Point of Contact</span>
          </div>

          {!hasPointOfContact && (
            <button
              className="btn btn-primary btn-sm text-nowrap"
              type="button"
              onClick={manageEmployerPointOfContact}
            >
              <span className='d-flex align-items-center'>
                <span>
                  Take Action
                </span>
                <FaChevronRight
                  className="ml-1 d-none d-md-inline-block"
                />
              </span>
            </button>
          )}
        </ChecklistRow>

        {!requiredWorkforceInformation && (
          <ChecklistRow>
            <div>
              <FaCheckCircle
                size={24}
                className={`${inviteStatuses[INVITE_TYPE_EMPLOYER_PROFILE_UPDATE] ? 'text-keppel' : 'text-muted opacity-25'}`}
              />
              <span className='d-flex align-items-center'>
                <span className='d-inline-block d-sm-none'>
                  Request Workforce Data
                </span>
                <span className='d-none d-sm-inline-block'>
                  Request Employer Update Workforce Data
                </span>
                <span
                  // data-tooltip-content="Workforce Information and Wage Schedules"
                  // data-tooltip-id="WorkforceInformationChecklist"
                  data-tip="Workforce Information and Wage Schedules"
                  data-for="WorkforceInformationChecklist"
                  className='ml-1'
                >
                  <FaInfoCircle
                    className='text-muted'
                  />
                </span>
              </span>
            </div>

            {!inviteStatuses[INVITE_TYPE_EMPLOYER_PROFILE_UPDATE] && (
              <button
                className={`btn ${employerProfileId ? 'btn-primary' : 'btn-outline-secondary'} btn-sm text-nowrap`}
                type="button"
                onClick={inviteToUpdateEmployerProfile}
                disabled={!employerProfileId}
              >
                <span className='d-flex align-items-center'>
                  <span>Take Action</span>
                  <FaChevronRight
                    className='ml-1 d-none d-md-inline-block'
                  />
                </span>
              </button>
            )}
          </ChecklistRow>
        )}

        <ChecklistRow>
          <div>
            <FaCheckCircle
              size={24}
              className={requiredWorkforceInformation ? 'text-keppel' : 'text-muted opacity-25'}
            />
            {/* <span>Employer Identification & Employee Demographics</span> */}
            <span>Workforce Information</span>
          </div>

          {!requiredWorkforceInformation && (
            <button
              className={`btn ${employerProfileId ? 'btn-outline-primary' : 'btn-outline-secondary'} btn-sm text-nowrap`}
              type="button"
              onClick={manageEmployerProfile}
              disabled={!employerProfileId}
            >
              <span className='d-flex align-items-center'>
                <span>
                  Enter Manually
                </span>
                <FaChevronRight
                  className="ml-1 d-none d-md-inline-block"
                />
              </span>
            </button>
          )}
        </ChecklistRow>

        <ChecklistRow>
          <div>
            <FaCheckCircle
              size={24}
              className={wageSchedules.list && wageSchedules?.list.length !== 0 ? 'text-keppel' : 'text-muted opacity-25'}
            />
            <span>Wage Schedules</span>
          </div>

          {(!wageSchedules?.list || wageSchedules?.list.length === 0) && (
            <button
              className={`btn btn-outline-primary btn-sm text-nowrap ${!employerProfileId && 'disabled'}`}
              onClick={createWageSchedule}
              title="Create Wage Schedule"
              type="button"
            >
              <span className='d-flex align-items-center'>
                <span>
                  Enter Manually
                </span>
                <FaChevronRight
                  className="ml-2 d-none d-md-inline-block"
                />
              </span>
            </button>
          )}
        </ChecklistRow>

        {!employerAgreement?.signerSignature && (
          <ChecklistRow>
            <div>
              <FaCheckCircle
                size={24}
                className={employerAgreement?.id ? 'text-keppel' : 'text-muted opacity-25'}
              />
              <span className='d-block d-sm-none'>
                Request Signature
              </span>
              <span className='d-none d-sm-block'>
                {inviteCtaSignAgreementMgmt}
              </span>
            </div>

            {(!requiredWorkforceInformation || !wageSchedules.list) ? (
              <span className='h6 mb-0 ml-2'>
                <span className='badge badge-warning px-2 py-1'>
                  Workforce Data Needed
                </span>
              </span>
            ) : (
              <button
                className={`btn ${employerAgreement?.id ? 'btn-outline-secondary' : 'btn-primary'} btn-sm text-nowrap`}
                type="button"
                onClick={inviteToSignEmployerAgreement}
              >
                <span className='d-flex align-items-center'>
                  <span>
                    {employerAgreement?.id ? 'New Request' : 'Send Request'}
                  </span>
                  <FaChevronRight
                    className="ml-2 d-none d-md-inline-block"
                  />
                </span>
              </button>
            )}
          </ChecklistRow>
        )}

        <ChecklistRow className="border-bottom-0">
          <div>
            <FaCheckCircle
              size={24}
              className={employerAgreement?.signerSignature ? 'text-keppel' : 'text-muted opacity-25'}
            />
            <span className='d-inline-block d-sm-none'>
              Agreement
            </span>
            <span className='d-none d-sm-inline-block'>
              Employer Agreement
            </span>

            {employerAgreement?.id && !employerAgreement?.signerSignature && (
              <span className='h6 mb-0 ml-2'>
                <span className='badge badge-warning px-2 py-1'>
                  Not Signed
                </span>
              </span>
            )}
          </div>

          {employerAgreement?.id && (
            <Link
              className="btn btn-primary btn-sm text-nowrap"
              to={routeWithOrgId({
                route: `${apprenticeshipRoute}/employer/${locationId}/docs`,
                orgId
              })}
              title="Employer Agreement"
            >
              <span className='d-inline-block d-sm-none'>
                Docs
              </span>
              <span className='d-none d-sm-inline-block'>
                Documents
              </span>
              <FaChevronRight
                className="ml-2 d-none d-md-inline-block"
              />
            </Link>
          )}
        </ChecklistRow>
      </Collapse>
      <ReactTooltip id="WorkforceInformationChecklist" />
    </>
  );
};

export default WorkforceInformationChecklist;

import React, { useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { FaPlus, FaSortAmountUp } from 'react-icons/fa';
import {
  canAccessAllLocations, isAdminOwnerSuperAdmin
} from '@apprentage/utils';
import { withAuthorization } from '../../../Session';
import { ORG_CONSOLE_WORKFORCE } from '../../../../constants/routes';
import { resetApprenticeship } from '../../../../actions/Apprenticeships';
import { resetWageSchedules } from '../../../../actions/Employers';
import { resetEmployerAgreements } from '../../../../actions/EmployerAgreements';
import { canCreateApprenticeship } from '../../../../permissions/create';
import { setCurrentModal } from '../../../../actions/Modals';
import { MODAL_KEY_CREATE_TRAINING_PROGRAM } from '../../../../constants/modals';
import withOrgConsole from '../../../App/withOrgConsole';
import OrgConsoleHeader from '../../OrgConsoleHeader';
import ApprenticeshipsList from '../../../lists/ApprenticeshipsList';

const Apprenticeships = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Current User
  const apprenticeshipIds = useMemo(() => {
    if (isAdminOwnerSuperAdmin(role)) {
      return undefined;
    }

    return currentUser?.apprenticeshipIds;
  }, [currentUser?.apprenticeshipIds, role]);

  useEffect(() => {
    dispatch(resetApprenticeship());
    dispatch(resetWageSchedules());
    dispatch(resetEmployerAgreements());
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Training Programs"
            iconComponent={() => <FaSortAmountUp size={20} />}
            route={ORG_CONSOLE_WORKFORCE}
            className="mb-3"
          >
            {canCreateApprenticeship(role) && (
              <button
                className="btn btn-sm btn-primary d-none d-sm-flex"
                type="button"
                onClick={() => {
                  dispatch(setCurrentModal({ key: MODAL_KEY_CREATE_TRAINING_PROGRAM }));
                }}
              >
                <span className='d-flex align-items-center'>
                  <FaPlus />
                  <span className='ml-1 font-weight-bold'>New</span>
                </span>
              </button>
            )}
          </OrgConsoleHeader>

          <div className="px-1 mt-3">
            <ApprenticeshipsList
              showControls
              hiddenColumns={canAccessAllLocations(role) ? [] : ['employerIds', 'ojtHours']}
              accessRestricted={!canAccessAllLocations(role)}
              ids={apprenticeshipIds}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Apprenticeships);

import {
  RESET_NOTE_APPRENTICESHIP_MATERIALS,
  SET_NOTE_APPRENTICESHIP_MATERIALS
} from '../../actions/types';

const initialState = {
  fetched: false,
  list: null,
  pagination: null
};

export default function noteApprenticeshipMaterials(state = initialState, action) {
  switch (action.type) {
    case SET_NOTE_APPRENTICESHIP_MATERIALS:
      return {
        ...state,
        fetched: true,
        list: action.list,
        pagination: action.pagination
      };
    case RESET_NOTE_APPRENTICESHIP_MATERIALS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}

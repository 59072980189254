import React from 'react';
import { roleReadable } from '@apprentage/utils';
import { useSelector } from 'react-redux';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import NameBlock from '@apprentage/components/dist/components/NameBlock';

const NameCell = ({
  value: name, row, showRole = false, handleClickRow
}) => {
  const organization = useSelector((state) => state.organization);

  return (
    <div className='overflow-hidden'>
      <NameBlock
        name={name}
        {...(handleClickRow
          ? { onClick: () => { handleClickRow(row.original); } }
          : {})}
        {...(showRole
          ? { role: roleReadable(row?.original?.role, organization?.type) }
          : {})}
        membership={row?.original.membership}
        testUser={row?.original.testUser}
        nameSize="sm"
        pictureSize="sm d-none d-sm-block"
        profilePicture={row?.original?.profilePicture || DEFAULT_PROFILE_PIC}
      />
    </div>
  );
};

export default NameCell;

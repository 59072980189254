export const GMAIL_SEND = '/v1/external/gmail/send';
export const WEATHER_BASE_URL = '/v1/external/weather';
export const FIREBASE_STORE_COLLECTION = '/v1/external/firebase/store';
export const COS_OCCUPATIONS = '/v1/external/cos/occupations';
export const COS_OCCUPATION = '/v1/external/cos/occupation';
export const COS_TRAINING_INSTITUTIONS = '/v1/external/cos/training-institutions';

export const OPEN_API_CREATE_COMPLETION = '/v1/external/openai/create-completion';
export const OPEN_API_SUMMARIZE_TEXT = '/v1/external/openai/summarizeText';

// Supabase
export const SUPABASE_BASE_URL = '/external/supabase';
export const SUPABASE_EMBED_DOC = '/v1/external/supabase/embedDoc';
export const SUPABASE_VECTOR_SEARCH = '/v1/external/supabase/vectorSearch';
export const SUPABASE_CHAT_ENGINE = '/v1/external/supabase/chatEngine';
export const SUPABASE_QUERY_ENGINE = '/v1/external/supabase/queryEngine';
export const SUPABASE_CONTENT_TYPES = [
  'materials',
  'projects', // Projects do NOT allow users to upload images/files in WYSIWYG
  'notifications',
  'resourceGroup' // TODO change to "resourceGroups"
];

// EMBED
export const API_EMBED_MATERIAL = '/v1/embed/material';

// SLACK
export const SLACK_REPORT_ISSUE_CHANNEL = '/v1/external/slack/reportIssue';
// CHEERIO
export const CHEERIO_PARSE_JD = '/v1/external/cheerio/parse-job-description';
export const CHEERIO_PARSE_ORG_CONTEXT = '/v1/external/cheerio/parse-org-context';
// IP API
export const API_IPAPI = '/v1/external/ipapi';
export const API_IPAPI_GEO = '/v1/external/ipapi/geocoding';
// DOCS API
export const CREATE_PDF_HTML = '/pdf/html';
export const CREATE_PDF_URL = '/pdf/url';
// AWS
export const API_AWS_UPLOAD = '/v1/external/aws/upload';
export const API_AWS_ACL = '/v1/external/aws/acl';
// PAGINATION
export const DEFAULT_PAGINATION_LIMIT = 20;
export const RESOURCE_TAGS_PAGINATION_LIMIT = 200;
export const DASHBOARD_PAGINATION_LIMIT = 1;
export const RECORDS_PAGINATION_LIMIT = 20;
export const NOTIFICATIONS_PAGINATION_LIMIT = 20;
export const PROJECTS_PAGINATION_LIMIT = 20;
export const MODULES_PAGINATION_LIMIT = 20;

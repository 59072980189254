import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GiTeacher } from 'react-icons/gi';
import { FaCog } from 'react-icons/fa';
import { canManageProgramUsers } from '../../permissions/manage';
import { setCurrentModal } from '../../actions/Modals';
import { MODAL_KEY_USER_APPRENTICESHIP_SETTINGS } from '../../constants/modals';

const UserInstructionProvider = ({ showIcon = false, className = '' }) => {
  const dispatch = useDispatch();
  // redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const currentUserRole = currentUser?.role || [];
  // Training Program
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserInstructionProvider = apprenticeship?.userInstructionProvider || null;

  return (
    <div className={className}>
      <div className='font-weight-bold'>
        Instruction Provider:
      </div>
      {apprenticeshipUserInstructionProvider?.id && (
        <div className='mt-2 d-flex align-items-center justify-content-between border p-2'>
          <div className='d-flex align-items-center'>
            {showIcon && (
              <GiTeacher
                size={25}
                className='mr-2'
                color="#8e44ad"
              />
            )}
            <span>
              {apprenticeshipUserInstructionProvider?.name || 'Community College of Allegheny County'}
            </span>
          </div>
          {canManageProgramUsers(currentUserRole) && (
            <button
              className="btn btn-sm btn-white"
              onClick={() => {
                dispatch(setCurrentModal({
                  key: MODAL_KEY_USER_APPRENTICESHIP_SETTINGS,
                  data: { user: apprenticeshipUser }
                }));
              }}
              aria-label="Settings"
              type="button"
            >
              <FaCog />
            </button>
          )}
        </div>
      )}

    </div>
  );
};

export default UserInstructionProvider;

import {
  fetchLogbookRecord,
  fetchLogbookRecords
} from '../services/logbookRecords';
import {
  RESET_LOGBOOK_RECORDS,
  RESET_LOGBOOK_RECORD,
  SET_LOGBOOK_RECORD,
  SET_LOGBOOK_RECORDS,
  SET_ENTRY_ORG_ID
} from './types';
import { RECORDS_PAGINATION_LIMIT } from '../constants/api';

export const getLogbookRecords = ({
  orgId,
  locationId,
  apprenticeshipId,
  competencyId,
  logbookId,
  gteDate,
  isApproved,
  approvedByUserId,
  order,
  page = 1,
  limit = RECORDS_PAGINATION_LIMIT
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchLogbookRecords({
        limit,
        orgId,
        page,
        ...(order ? { order } : {}),
        ...(logbookId ? { logbookId } : {}),
        ...(gteDate ? { gteDate } : {}),
        ...(isApproved !== undefined ? { isApproved } : {}),
        ...(approvedByUserId ? { approvedByUserId } : {}),
        ...(competencyId ? { competencyId } : {}),
        ...(apprenticeshipId ? { apprenticeshipId } : {}),
        ...(locationId ? { locationId } : {})
      })
        .then((response) => {
          const list = response?.items || [];

          const responseData = {
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          };

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_LOGBOOK_RECORDS,
            ...responseData
          });

          resolve(responseData);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getLogbookRecord = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (id) {
        fetchLogbookRecord(id)
          .then((logbookRecord) => {
            // ORG CHECK
            dispatch({
              type: SET_ENTRY_ORG_ID,
              entryOrgId: logbookRecord?.orgId || null
            });

            dispatch({
              type: SET_LOGBOOK_RECORD,
              logbookRecord
            });
            resolve(logbookRecord);
          })
          .catch((error) => {
            console.error('getLogbookRecord', error);
            reject(error);
          });
        return;
      }

      reject(new Error('getLogbookRecord, missing params'));
    });
  };
};

export const resetLogbookRecord = () => {
  return (dispatch) => {
    dispatch({ type: RESET_LOGBOOK_RECORD });
  };
};

export const resetLogbookRecords = () => {
  return (dispatch) => {
    dispatch({ type: RESET_LOGBOOK_RECORDS });
  };
};

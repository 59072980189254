import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { canAccessAllLocations, isProgramManager, isProgramTrainer } from '@apprentage/utils';
import pluralize from 'pluralize';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { resetUsers } from '../../../../../actions/Users';
import UsersList from '../../../../UsersList';
import { resetCronUsers } from '../../../../../actions/CronUsers';
import { canAccessAllApprenticeshipUsers, canAccessUserWageSchedule } from '../../../../../permissions/access';
import { canManageLogbookReminders } from '../../../../../permissions/manage';
import { getLogbooks } from '../../../../../actions/Logbooks';

const TraineesTable = ({
  className = ''
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentUser = useSelector((state) => state.currentUser);
  const users = useSelector((state) => state.users);
  // Organization
  const orgId = organization?.id || null;
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  const locationId = currentUser?.locationId || null;
  // Routing
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
  const employersRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}/employers`;

  const hiddenColumns = useMemo(() => {
    const cols = ['role'];

    if (isProgramManager(role) || isProgramTrainer(role)) {
      cols.push('userProfileId');
      cols.push('isApprentice');
    }

    if (!canAccessAllLocations(role)) {
      cols.push('locationId');
    }

    if (!canAccessUserWageSchedule(role)) {
      cols.push('wageScheduleCell');
    }

    if (!canManageLogbookReminders(role)) {
      cols.push('remindersCell');
    }

    return cols;
  }, [role]);

  const handleClickRow = (clickedUser) => {
    history.push(`${apprenticeshipRoute}/users/${clickedUser.id}`);
  };

  const userListConfig = useMemo(() => ({
    orgId,
    apprenticeshipId,
    roles: ['student'],
    excludeTestUsers: true,
    excludeInactive: true,
    ...(!canAccessAllApprenticeshipUsers(role) ? { locationId } : {}),
    ...(isProgramTrainer(role) || isProgramManager(role) ? { supervisorIds: [currentUser?.id] } : {})
  }), [apprenticeshipId, currentUser?.id, locationId, orgId, role]);

  const [listConfig] = useState(userListConfig);

  const retrieveLogbooks = useCallback(() => {
    // if (showLogbookRecords) {
    dispatch(getLogbooks({
      orgId,
      ...(apprenticeshipId ? { apprenticeshipId } : {}),
      ...(canAccessAllApprenticeshipUsers(role) ? {} : { employerId: locationId })
    }));
    // }
  }, [apprenticeshipId, dispatch, locationId, orgId, role]);

  useEffect(() => {
    retrieveLogbooks();
  }, [retrieveLogbooks]);

  useEffect(() => {
    return function cleanup() {
      const { href } = window?.location;

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetUsers());
        dispatch(resetCronUsers());
      }
    };
  }, [apprenticeshipId, apprenticeshipRoute, dispatch]);

  return (
    <div className={`card-body h-100 shadow border border-1 ${className}`}>
      <div className="d-flex justify-content-between flex-row mb-4">
        <div className="h4 m-0">
          My {pluralize('Trainee', Array.isArray(users?.list) ? users?.list.length : 0)}
        </div>
        {canAccessAllLocations(role) && (
          <div>
            <button
              type="button"
              className="btn btn-link btn-sm"
              onClick={() => {
                history.push(employersRoute);
              }}
            >
              <span className='d-flex align-items-center'>
                <span className='mr-2'>
                  View All
                </span>
                <FaArrowAltCircleRight />
              </span>
            </button>
          </div>
        )}
      </div>
      <UsersList
        className='border-top border-left border-right'
        showLogbookRecords
        hiddenColumns={hiddenColumns}
        handleClickRow={handleClickRow}
        showRole
        listConfig={listConfig}
      />
    </div>
  );
};

export default TraineesTable;

import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  reactSelectDefaultValue,
  reactSelectDefaultValues,
  reactSelectOptionsFromEnum,
  roleReadable
} from '@apprentage/utils';
import { FaUserCog } from 'react-icons/fa';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import NameBlock from '@apprentage/components/dist/components/NameBlock';
import { resetCurrentModal } from '../../../actions/Modals';
import { updateUser } from '../../../services/user';
import { locationTerminology } from '../../../services/locations';
import {
  canAccessUserGroups,
  canDeleteUserSoft,
  canManageUserRoles,
  isActiveMembership
} from '../../../services/currentUser';
import Modal from '../../Modal';
import SelectMenu from '../../ManageContent/SelectMenu';
import UserRoleDefinitions from '../../UserRoleDefinitions';
import RadioGroup from '../../ManageContent/RadioGroup';
import DeleteUserSoft from '../../ManageContent/DeleteContent/DeleteUserSoft';

const UserSettings = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  const locations = useSelector((state) => state.locations);
  const course = useSelector((state) => state.course);
  const courseCohorts = useSelector((state) => state.courseCohorts);
  const userGroups = useSelector((state) => state.userGroups);
  // User
  const user = currentModal?.data?.user || null;
  const userId = user?.id || null;
  // Organization
  const orgType = organization?.type || '';
  const enableUserGroups = organization?.enableUserGroups || false;
  const enableWorkforceSuite = organization?.enableWorkforceSuite || false;
  const locationTerm = locationTerminology({ orgType });
  // Current User
  const currentUserRole = currentUser?.role || [];
  // Local State
  const [loading, setLoading] = useState(false);
  // Test User
  const [testUser, setTestUser] = useState(user?.testUser || false);
  const [testUserChanged, setTestUserChanged] = useState(false);
  // Apprentice
  const [isApprentice, setIsApprentice] = useState(user?.isApprentice || false);
  const [isApprenticeChanged, setIsApprenticeChanged] = useState(false);
  // Location
  const [locationId, setLocationId] = useState(user?.locationId || null);
  const [locationIdChanged, setLocationIdChanged] = useState(false);
  // Role
  const [role, setRole] = useState(user?.role || null);
  const [roleChanged, setRoleChanged] = useState(false);
  // Cohorts
  const [cohortIds, setCohortIds] = useState(user?.cohortIds || []);
  const [cohortIdsChanged, setCohortIdsChanged] = useState(false);
  // Groups
  const [groupIds, setGroupIds] = useState(user?.groupIds || []);
  const [groupIdsChanged, setGroupIdsChanged] = useState(false);
  // User Membership Inactive
  const [isActive, setIsActive] = useState(
    isActiveMembership(user?.membership)
  );
  const [isActiveChanged, setIsActiveChanged] = useState(false);

  // if (!locations.list || !locations.list.length) {
  //   return false;
  // }

  const userCohortIdsOptions = courseCohorts.list
    ? courseCohorts.list.reverse().map((cohort) => {
      return {
        label: cohort.title,
        value: cohort.id
      };
    })
    : null;

  const userLocationOptions = locations.list
    ? locations.list.map((location) => {
      return {
        label: location.name,
        value: location.id
      };
    })
    : null;

  const userGroupIdsOptions = userGroups.list
    ? userGroups.list.map((group) => {
      return {
        label: group.title,
        value: group.id,
        itemIds: group.itemIds
      };
    })
    : null;

  const trainingProgramUserRoles = {
    programManager: 'Program Manager',
    programTrainer: 'Program Trainer'
  };

  const userRolesEnum = {
    student: orgType !== 'workforce' ? 'Trainee' : 'User',
    teacher: 'Expert',
    admin: 'Admin',
    owner: 'Owner',
    ...(enableWorkforceSuite ? trainingProgramUserRoles : {})
  };

  const userRoleOptions = reactSelectOptionsFromEnum(userRolesEnum);

  // Default Values
  const userLocationDefaultValue = reactSelectDefaultValue(
    locationId,
    userLocationOptions
  );
  const userCohortIdsDefaultValues = reactSelectDefaultValues(
    cohortIds,
    userCohortIdsOptions
  );
  const userGroupIdsDefaultValues = reactSelectDefaultValues(
    groupIds,
    userGroupIdsOptions
  );
  const userRoleDefaultValue = reactSelectDefaultValues(role, userRoleOptions);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const dataToSave = {
      ...(testUserChanged ? { testUser: testUser || null } : {}),
      ...(isApprenticeChanged ? { isApprentice } : {}),
      ...(locationIdChanged ? { locationId } : {}),
      ...(roleChanged ? { role } : {}),
      ...(groupIdsChanged ? { groupIds } : {}),
      ...(cohortIdsChanged ? { cohortIds } : {}), // TODO use classCohortIds - update formatUser
      ...(isActiveChanged
        ? { role: 'student', membership: isActive ? 'starter' : 'inactive' }
        : {})
    };

    updateUser(dataToSave, userId).then(() => {
      toast.success('User Advanced Settings saved!');
      // dispatch(resetUsers());
      setTimeout(() => {
        document.location.reload();
      }, 250);
      // dispatch(getUsers({})).then(() => {
      //   setLoading(false);
      //   handleClose();
      // });
    });
  };

  const dataHasChanged = useMemo(() => {
    if (
      locationIdChanged
      || roleChanged
      || isApprenticeChanged
      || isActiveChanged
      || testUserChanged
    ) {
      return true;
    }

    // SelectMenu (isMulti)
    if (cohortIdsChanged || groupIdsChanged) {
      return true;
    }

    return false;
  }, [
    locationIdChanged,
    roleChanged,
    isApprenticeChanged,
    isActiveChanged,
    testUserChanged,
    cohortIdsChanged,
    groupIdsChanged
  ]);

  return (
    <Modal
      cssClassName={`turbine-modal--${currentModal?.key}`}
      visible={currentModal.visible}
      close={handleClose}
      theme="dark"
    >
      <div className="card">
        <div className="card-header bg-dark text-white">
          <h1 className="h5 m-0 d-flex align-items-center">
            <FaUserCog size={23} className='mr-2' />
            <span>
              Advanced Settings
            </span>
          </h1>
        </div>
        <form onSubmit={onSubmit}>
          <NameBlock
            name={user?.name}
            role={roleReadable(user?.role, orgType)}
            profilePicture={user?.profilePicture || DEFAULT_PROFILE_PIC}
            nameSize='lg'
            pictureSize='sm'
            className="p-3 border-bottom"
          />
          <div className="card-body">
            {/* Location */}
            <div className="mb-3">
              <div className="mb-2 font-weight-bold">{locationTerm.text}:</div>
              <SelectMenu
                onChange={({ locationId: newLocationId }) => {
                  setLocationId(newLocationId);
                  setLocationIdChanged(true);
                }}
                defaultValue={userLocationDefaultValue}
                name="locationId"
                options={userLocationOptions}
              />
            </div>

            {/* Role */}
            {canManageUserRoles(currentUserRole) && (
              <div className="mb-3">
                <div className="mb-2 font-weight-bold">Role:</div>
                <SelectMenu
                  onChange={({ role: newRole }) => {
                    setRole(newRole);
                    setRoleChanged(true);
                  }}
                  defaultValue={userRoleDefaultValue}
                  name="role"
                  options={userRoleOptions}
                />
                <UserRoleDefinitions />
              </div>
            )}

            <div className="form-group mb-3">
              <div className="font-weight-bold">Test User:</div>
              <div className="text-muted medium mb-2">
                User used for testing purposes.
              </div>
              <RadioGroup
                name="testUser"
                value={testUser}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setTestUser(value === 'true');
                  setTestUserChanged(true);
                }}
              />
            </div>

            {/* Apprentice */}
            {enableWorkforceSuite && (
              <div className="form-group mb-3">
                <div className="font-weight-bold">Apprentice:</div>
                <div className="text-muted medium mb-2">
                  Apprentices are required to fill out sensitive user
                  information including SSN.
                </div>
                <RadioGroup
                  name="isApprentice"
                  value={isApprentice}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setIsApprentice(value === 'true');
                    setIsApprenticeChanged(true);
                  }}
                />
              </div>
            )}

            {/* Groups */}
            {canAccessUserGroups(currentUserRole, enableUserGroups) && userGroupIdsOptions && (
              <div className="mb-3">
                <div className="mb-2 font-weight-bold">User Groups</div>
                <SelectMenu
                  name="groupIds"
                  options={userGroupIdsOptions}
                  defaultValue={userGroupIdsDefaultValues}
                  onChange={({ groupIds: newGroupIds }) => {
                    setGroupIds(
                      newGroupIds.length === 0 ? null : newGroupIds
                    );
                    setGroupIdsChanged(true);
                  }}
                  isMulti
                />
              </div>
            )}

            {/* Cohorts */}
            {course.id && courseCohorts.list && userCohortIdsOptions && (
              <div className="mb-3">
                <div className="mb-2 font-weight-bold">Course Cohorts</div>
                <SelectMenu
                  name="cohortIds"
                  options={userCohortIdsOptions}
                  defaultValue={userCohortIdsDefaultValues}
                  onChange={({ cohortIds: newCohortIds }) => {
                    setCohortIds(
                      newCohortIds.length === 0 ? null : newCohortIds
                    );
                    setCohortIdsChanged(true);
                  }}
                  isMulti
                />
              </div>
            )}

            {canDeleteUserSoft(currentUserRole) && (
              <DeleteUserSoft>
                <div>
                  <div className="font-weight-bold">User Status:</div>
                  <div className="text-muted medium mb-2">
                    Setting a user's status to inactive prevents them from
                    accessing Turbine
                    {orgType === 'workforce'
                      ? '.'
                      : ' except for their profile.'}
                  </div>
                  <RadioGroup
                    name="isActive"
                    value={isActive}
                    labelYes="Active"
                    labelNo="Inactive"
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setIsActive(value === 'true');
                      setIsActiveChanged(true);
                    }}
                  />
                </div>
              </DeleteUserSoft>
            )}
          </div>
          <div className="card-footer">
            <button
              className="btn btn-primary btm-sm"
              type="submit"
              disabled={loading || !dataHasChanged}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
            <button
              className="btn btn-link btm-sm ml-2"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UserSettings;

import React, { useMemo } from 'react';
import NameBlock from '@apprentage/components/dist/components/NameBlock';
import { useDispatch, useSelector } from 'react-redux';
// import { roleReadable } from '@apprentage/utils';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import OrgConsoleSubheader from '../../../OrgConsoleSubheader';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { setSideSheet } from '../../../../../actions/SideSheets';
import { SHEET_KEY_USER_DETAILS } from '../../../../../constants/sideSheets';
import DotMenu from './DotMenu';

const UserOrgConsoleSubheader = ({
  showMenu = false
}) => {
  const dispatch = useDispatch();
  // Redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // const organization = useSelector((state) => state.organization);
  const locations = useSelector((state) => state.locations);
  // Organization
  // const orgType = organization?.type || null;
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserLocationId = apprenticeshipUser?.locationId || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
  // Location
  const orgLocation = useMemo(() => {
    const locationsHash = locations?.hash || {};
    return locationsHash[apprenticeshipUserLocationId] || {};
  }, [apprenticeshipUserLocationId, locations?.hash]);

  // const locationImage = orgLocation?.locationImage || null;
  const apprenticeshipUserLocationName = orgLocation?.name || null;
  return (
    <OrgConsoleSubheader
      route={`${apprenticeshipRoute}/users`}
    >
      <NameBlock
        profilePicture={apprenticeshipUser?.profilePicture || DEFAULT_PROFILE_PIC}
        name={apprenticeshipUser?.name}
        title={apprenticeshipUserLocationName}
        // role={roleReadable(apprenticeshipUser?.role, orgType)}
        nameSize="md"
        nameClassName='text-3f5366 font-weight-bold'
        pictureSize="sm"
        onClick={() => {
          dispatch(
            setSideSheet({
              key: SHEET_KEY_USER_DETAILS
            })
          );
        }}
      />
      {showMenu && (<DotMenu />)}
    </OrgConsoleSubheader>
  );
};

export default UserOrgConsoleSubheader;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCog, FaUser } from 'react-icons/fa';
import { useFlags } from 'flagsmith/react';
import { roleReadable } from '@apprentage/utils';
import Loading from '../../../../Loading';
import InfoField from '../../../../InfoField';
import OrgLocation from '../../../../OrgLocation';
import KeyValueItem from '../../../../KeyValueItem';
import UserInstructionProvider from '../../../../UserInstructionProvider';
import { FLAG_USERS_READONLY, NOTICE_USERS_READONLY_BODY, NOTICE_USERS_READONLY_TITLE } from '../../../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../../../actions/FeatureFlags';
import { setCurrentModal } from '../../../../../actions/Modals';
import { MODAL_KEY_USER_SETTINGS } from '../../../../../constants/modals';
import { canManageUsers } from '../../../../../services/currentUser';

const InfoBlock = () => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_USERS_READONLY]);
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Current User
  const role = currentUser?.role || [];
  // Training Program : User
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserRole = apprenticeshipUser.role || [];

  return (
    <>
      {apprenticeshipUser ? (
        <div className='card'>
          <div className='card-header'>
            <h6 className="m-0 font-weight-bold">
              <span className='d-flex align-items-center'>
                <FaUser size={18} />
                <span className="ml-2 text-nowrap">User Information</span>
              </span>
            </h6>
          </div>
          <div className='card-body'>
            <div className='mb-3'>
              <div className='font-weight-bold'>
                Role:
              </div>
              <div
                className='mt-2 d-flex align-items-center justify-content-between border p-2'
              >
                <span>
                  {roleReadable(apprenticeshipUserRole)}
                </span>
                {canManageUsers(role) && (
                  <button
                    className="btn btn-sm btn-white"
                    type="button"
                    aria-label='Settings'
                    onClick={() => {
                      if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
                        dispatch(showFeatureFlagNoticeModal({
                          modalTitle: NOTICE_USERS_READONLY_TITLE,
                          modalBody: NOTICE_USERS_READONLY_BODY
                          // preventClose: true
                        }));
                        return;
                      }

                      dispatch(setCurrentModal({
                        key: MODAL_KEY_USER_SETTINGS,
                        data: { user: apprenticeshipUser }
                      }));
                    }}
                  >
                    <FaCog />
                  </button>
                )}
              </div>
            </div>

            <div className='mb-3'>
              <div className='font-weight-bold'>
                Employer:
              </div>
              <div
                className='mt-2 d-flex align-items-center justify-content-between border p-2'
              >
                <OrgLocation
                  locationId={apprenticeshipUser.locationId}
                />

                {canManageUsers(role) && (
                  <button
                    className="btn btn-sm btn-white"
                    type="button"
                    aria-label='Settings'
                    onClick={() => {
                      if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
                        dispatch(showFeatureFlagNoticeModal({
                          modalTitle: NOTICE_USERS_READONLY_TITLE,
                          modalBody: NOTICE_USERS_READONLY_BODY
                          // preventClose: true
                        }));
                        return;
                      }

                      dispatch(setCurrentModal({
                        key: MODAL_KEY_USER_SETTINGS,
                        data: { user: apprenticeshipUser }
                      }));
                    }}
                  >
                    <FaCog />
                  </button>
                )}
              </div>
            </div>

            <UserInstructionProvider
              className="mb-3"
            />

            <InfoField
              className="mb-2"
              name="Phone"
              value={apprenticeshipUser.phone}
            />

            <KeyValueItem
              title="Email"
              value={apprenticeshipUser.email}
              size="md"
            />
          </div>
        </div>
      ) : (
        <div className='position-relative'>
          <Loading
            className='position-absolute h-auto'
            text='Loading User Info...'
          />
        </div>
      )}
    </>
  );
};

export default InfoBlock;

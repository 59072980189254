import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { fetchTrainingInstitutions } from '../../../../../services/instructionProviders';
import { setCurrentModal } from '../../../../../actions/Modals';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import InstructionProvidersList from '../../../../lists/InstructionProvidersList';

const InstructionProviders = () => {
  const dispatch = useDispatch();
  // Redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const userLocation = useSelector((state) => state.userLocation);
  const zipCode = userLocation?.addressPostalCode || '';
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || '';
  const instructionProviderIds = apprenticeship?.instructionProviderIds || null;
  const hasInstructionProviders = Array.isArray(apprenticeship?.instructionProviderIds)
    && apprenticeship.instructionProviderIds.length > 0;
  // Local State
  const [trainingInstitution, setTrainingInstitutions] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTheLocationDataWithIp = async (zip) => {
      try {
        setLoading(true);
        await getTrainingInstitutions(zip);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    if (zipCode) {
      fetchTheLocationDataWithIp(zipCode);
    }
  }, [zipCode]);

  const getTrainingInstitutions = async (postalCode) => {
    try {
      const response = await fetchTrainingInstitutions({
        location: postalCode
      });
      if (response?.SchoolPrograms && response?.SchoolPrograms.length > 0) {
        setTrainingInstitutions(response?.SchoolPrograms);
      } else {
        setTrainingInstitutions([]);
      }
    } catch (error) {
      setTrainingInstitutions([]);
      console.error(error);
    }
  };

  if (hasInstructionProviders) {
    return (
      <div className="shadow p-4 pt-2 rounded rounded-2 h-100 border border-1">
        <div className="d-flex justify-content-between flex-row mb-4">
          <div className="h4 m-0">Instruction Providers</div>
          <Link
            className="btn btn-sm btn-link m-0 p-0"
            to={`${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}/instruction-providers`}
          >
            <span className="d-flex align-items-center">
              <span className="mr-2">View All</span>
              <FaArrowAltCircleRight />
            </span>
          </Link>
        </div>
        <div className="d-flex h-100 flex-column justify-content-between">
          <InstructionProvidersList
            instructionProviderIds={instructionProviderIds}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="shadow p-4 pt-2 rounded rounded-2 h-100 border border-1">
      <div className="d-flex h-100 flex-column justify-content-between">
        <div className="d-flex flex-row justify-content-between">
          <div className="h4">Instruction Providers</div>
          <Link
            className="btn btn-sm btn-link m-0 p-0"
            to={`${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}/instruction-providers`}
          >
            <span className="d-flex align-items-center">
              <span className="mr-2">View All</span>
              <FaArrowAltCircleRight />
            </span>
          </Link>
        </div>
        <div className="mb-2">
          <p className="h6 font-weight-bold">
            {`Available Instruction Provider's in ${zipCode}`}
          </p>
        </div>
        <div className="mb-2">
          <ul>
            {trainingInstitution?.slice(0, 4).map((data) => (
              <li key={data.ID}>{data.SchoolName}</li>
            ))}
          </ul>
          {loading && (
            <div
              className="spinner-border align-self-center"
              role="status"
            />
          )}
          {!loading && trainingInstitution?.length === 0 && (
            <div>No data found.</div>
          )}
        </div>
        <div>
          <button
            className="btn btn-outline-primary btn-sm"
            type="button"
            onClick={() => {
              dispatch(
                setCurrentModal({
                  key: 'changePinInstructionProvider'
                })
              );
            }}
          >
            Choose a Provider
          </button>
        </div>
      </div>
    </div>
  );
};

export default InstructionProviders;

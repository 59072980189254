import { toast } from 'react-toastify';
import { TURBINE_PAGE } from '../constants/urls';
import { updateFirebaseEntry } from './firebaseProxy';

export const previewLink = (id) => {
  return `${TURBINE_PAGE}/external/job-profiles/${id}`;
};

export const jobProfileManageIframeUrl = ({
  jobProfileId, orgId
}) => {
  let url = `${TURBINE_PAGE}/external/pathways`;

  if (jobProfileId) url += `/${jobProfileId}`;

  url += '?embedded=true';
  url += `&orgId=${orgId}`;

  return url;
};

export const jobProfileIframeUrl = ({
  jobProfileId, orgId, hideHeaderTitle = false
}) => {
  let url = `${TURBINE_PAGE}/external/job-profiles`;

  if (jobProfileId) url += `/${jobProfileId}`;

  url += '?embedded=true';
  url += `&orgId=${orgId}`;

  if (hideHeaderTitle) url += `&hideHeaderTitle=${hideHeaderTitle}`;

  return url;
};

/**
 *
 * @param {string} pathwayId
 * @param {object} data
 */
export const updatePathway = (pathwayId, data) => {
  return new Promise((resolve) => {
    updateFirebaseEntry({
      collection: 'pathways',
      id: pathwayId,
      data
    }).then(() => {
      resolve();
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error('update pathway', error);
    });
  });
};

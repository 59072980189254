import { RESET_NOTE_USER_MATERIALS, SET_NOTE_USER_MATERIALS } from '../../actions/types';

const initialState = {
  fetched: false,
  list: null,
  pagination: null
};

export default function noteUserMaterials(state = initialState, action) {
  switch (action.type) {
    case SET_NOTE_USER_MATERIALS:
      return {
        ...state,
        fetched: true,
        list: action.list,
        pagination: action.pagination
      };
    case RESET_NOTE_USER_MATERIALS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
